<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab></mypage-tab>


    <template v-if="list && list.length > 0 ">

      <div class="filter-btn-section btn-set" >
        <div>
          <button class="btn color-11 btn-outline" @click="save">반려동물 등록</button>
        </div>
      </div>

      <div class="mypage-mypet-list">

        <div class="mypet-item" v-for="(item, index) in list" :key="`mypet-${index}`">
          <div>
            <div class="profile-img-box" @click="modify(item.idx)">
              <div class="profile-img">
                <image-alt :src="item.thumbnail" altType="profile"></image-alt>
              </div>
            </div>
            <div class="profile-info">
              <div @click="modify(item.idx)">
                <h1><div class="name">{{item.name}}</div>  <div class="icon-paw" v-if="item.isMain == 1"></div> </h1>
                <div class="info"><span>{{item.breedName}}</span> <span>{{ $calcAge(item.birthday) }}</span></div>
              </div>
            </div>
          
            <div class="edite-btn">
              <div @click.prevent="modify(item.idx)">수정</div>
              <div @click.prevent="confirmRemovePopup(item.idx)">삭제</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="member-layout pb-0">
    
      <div class="complete-msg" >
        <img src="@/assets/svg/myPetList_cat.svg">
        <h1>우리 아이 정보 등록하고<br>맞춤 정보&amp;혜택 받아보세요!</h1>
        <router-link to="/mypet/register">반려동물 등록하기</router-link>
      </div>
    </div>


  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "mypetList",
  data() {
    return {
      list :[],
      idx:null,
      isModify: false,
      popupMsg: null,
      isOpenPopup: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, list } = await this.$api.getPetsList()
      if (result === 'success') this.list =  list
    },
    modify(idx){
      this.$router.push(`/mypet/modify/${idx}`)
    },
    save() {
      this.$router.push('/mypet/register')
    },
    confirmRemovePopup(idx) {
      if (!idx) return false
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          const { result } = await this.$api.removePets({idx : idx})
          if (result === 'success') {
            this.setMsgPopup()
            await this.getList();
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
